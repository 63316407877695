<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>
							Get price per kg and per box of product for
							customer.
						</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<router-link
								to="/history"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-calendar"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										View
									</span>
									History
								</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="ng-block">
			<calculator
				:costs="costs"
				:customers="customers"
				:products="products"
				:pallets="pallets"
				@calculated="viewCalculation"
				:user="user"
			/>
		</div>
	</div>
</template>
<script lang="ts">
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import Calculator from "../../../../common/components/Calculate.vue";

	import router from "../router";
	import http from "../../../../common/services/http";
	import store from "../../../../common/services/store";

	export default defineComponent({
		components: {
			Calculator,
		},
		setup() {
			const customers = ref([]);
			const costs = ref([]);
			const products = ref([]);
			const packabilities = ref([]);
			const pallets = ref([]);

			const { user } = toRefs(store.state);

			function viewCalculation(result: any) {
				router.replace(`/calculations/${result.id}`);
			}

			onMounted(() => {
				http.get(`/api/users?role=customer&fetch=all`).then(
					(res: any) => {
						customers.value = res.data;
					}
				);

				http.get(`/api/costs?fetch=all`).then((res: any) => {
					costs.value = res.data;
				});

				http.get(`/api/products?fetch=all`).then((res: any) => {
					products.value = res.data;
				});

				http.get(`/api/pallet-types?fetch=all`).then((res: any) => {
					pallets.value = res.data;
				});
			});

			return {
				user,
				customers,
				costs,
				products,
				pallets,
				viewCalculation,
			};
		},
	});
</script>
