<template>
	<div class="card card-bordered">
		<div class="card-body">
			<form @submit.prevent="makeCalculation" class="row">
				<div class="col-md-5 form-group">
					<label class="control-label">Select Customer</label>
					<Multiselect
						:options="customers"
						v-model="calc.user_id"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Start typing..."
					/>
				</div>

				<div class="col-md-5 form-group">
					<label class="control-label">Select Product</label>
					<Multiselect
						:options="products"
						v-model="calc.product_id"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Type product to search..."
						@change="setProduct"
					/>
				</div>

				<div class="col-md-2 form-group">
					<label class="control-label">Loose/Prepack</label>
					<Multiselect
						:options="states"
						:searchable="true"
						v-model="calc.state"
						placeholder="Select"
						@change="setPackability"
					/>
				</div>

				<div class="col-8 col-md-4 form-group">
					<label class="control-label">Raw Material Costs</label>
					<input
						readonly="readonly"
						id="raw_cost"
						type="number"
						class="form-control form-control-lg"
						name="raw_cost"
						v-model="calc.raw_cost"
						@change="setCosts"
						@keyup="setCosts"
					/>
				</div>

				<div class="col-4 col-md-4 form-group">
					<label class="control-label">Packability(%)</label>
					<input
						readonly="readonly"
						id="product_packability"
						type="text"
						class="form-control form-control-lg"
						name="product_packability"
						v-model="calc.product_packability"
						@change="setCosts"
						@keyup="setCosts"
					/>
				</div>

				<div
					class="col-9 form-group"
					:class="user.role == 'admin' ? 'col-md-3' : 'col-md-4'"
				>
					<label class="control-label">Total Product Cost</label>
					<input
						readonly="readonly"
						id="total_prod_cost"
						type="text"
						class="form-control form-control-lg"
						name="prod_cost"
						v-model="calc.prod_cost"
					/>
				</div>

				<div
					class="col-3 form-group align-items-end text-center mt-1"
					:class="user.role == 'admin' ? 'col-md-1' : 'd-none'"
					v-if="user.role == 'admin'"
				>
					<label
						class="control-label d-none d-sm-inline-block d-md-inline-block"
					>
						Edit
					</label>
					<button
						id="edit_fields"
						class="btn btn-icon btn-round btn-warning btn-rounded"
						@click.prevent="makeEditable"
					>
						<em class="icon ni ni-pen"></em>
					</button>
				</div>

				<div class="col-4 col-md-4">
					<div class="form-group">
						<label class="control-label">Pack Weight(g)</label>
						<input
							id="pack_weight"
							type="number"
							class="form-control form-control-lg"
							name="pack_weight"
							v-model="calc.pack_weight"
							@keyup="setWeight"
						/>
					</div>
				</div>

				<div class="col-4 col-md-4">
					<div class="form-group">
						<label class="control-label">Pack Count(Box)</label>
						<input
							id="pack_count"
							type="text"
							class="form-control form-control-lg"
							name="pack_count"
							v-model="calc.pack_count"
							@keyup="setWeight"
						/>
					</div>
				</div>

				<div class="col-4 col-md-4 form-group">
					<label class="control-label">Box Weight(Kg)</label>
					<input
						readonly="readonly"
						id="box_weight"
						type="text"
						class="form-control form-control-lg"
						name="box_weight"
						v-model="calc.box_weight"
					/>
				</div>

				<div class="col-md-4 form-group">
					<label class="control-label">Primary Packaging</label>
					<Multiselect
						:options="getCosts('primary-packaging')"
						v-model="calc.primary_packaging"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Type to search..."
					/>
				</div>

				<div class="col-md-4 form-group">
					<label class="control-label">Labelling Costs</label>
					<Multiselect
						:options="getCosts('labelling')"
						v-model="calc.labelling"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Type to search..."
					/>
				</div>

				<div class="col-md-4 form-group">
					<label class="control-label">Box Type</label>
					<Multiselect
						:options="getCosts('box-types')"
						v-model="calc.box_type"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Type to search..."
					/>
				</div>

				<div class="col-md-5 form-group">
					<label class="control-label">Shipping Formats</label>
					<Multiselect
						:options="pallets"
						v-model="calc.pallet_type_id"
						trackBy="name"
						label="name"
						valueProp="id"
						:searchable="true"
						placeholder="Type to search..."
					/>
				</div>

				<div class="col-md-4 form-group">
					<label class="control-label">Optional Margin (%)</label>
					<input
						type="number"
						class="form-control form-control-lg"
						name="margin"
						v-model="calc.margin"
					/>
				</div>

				<div class="col-md-3 pt-5 form-group">
					<button
						type="submit"
						class="btn btn-primary btn-rounded btn-block btn-lg mt-n1"
					>
						<em class="icon ni ni-calc"></em>
						<span>
							Calculate
						</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, reactive, ref, computed } from "vue";
	import http from "../../common/services/http";

	export default defineComponent({
		props: {
			products: Array,
			costs: Array,
			customers: Array,
			user: Object,
			pallets: Array,
		},

		emits: ["calculated"],

		setup(props, { emit }) {
			const product = ref({});
			const calc = reactive({
				user_id: 0,
				product_id: 0,
				state: "select",
				raw_cost: 0,
				product_packability: 75,
				prod_cost: "0.0",
				pack_weight: 0,
				pack_count: 0,
				primary_packaging: 0,
				labelling: 0,
				box_type: 1,
				box_weight: "0.0",
				pallet_type_id: 0,
				margin: 0,
			});
			const states = ref([
				{ value: "select", label: "Select" },
				{ value: "loose", label: "Loose" },
				{ value: "prepack", label: "Prepack" },
			]);

			const boxWeight = computed(() => {
				return ((calc.pack_weight * calc.pack_count) / 1000).toFixed(2);
			});

			function getCosts(type: any) {
				return props.costs?.filter(
					(cost: any) => cost?.category?.slug == type
				);
			}

			function setCosts() {
				const perc = product.value["packability"] / 100;

				calc.prod_cost = (calc.raw_cost / perc).toFixed(2);
			}

			function setProduct(p: any) {
				calc.product_id = p;

				const pr: any = props.products?.find((pt: any) => pt.id == p);
				product.value = pr;
				calc.raw_cost = pr.cost;
			}

			function setWeight() {
				calc.box_weight = boxWeight.value;
			}

			function setPackability(state: any) {
				calc.product_packability = product.value["packability"];

				setCosts();
			}

			function makeCalculation() {
				http.post("/api/calculations", calc).then((res: any) => {
					emit("calculated", res.data);
				});
			}

			function makeEditable(e: any) {
				e.preventDefault();
				const xs = document.getElementsByTagName("input");

				for (let i = 0; i < xs.length; i++) {
					xs[i].removeAttribute("readonly");
				}
			}

			return {
				states,
				calc,
				product,
				setProduct,
				boxWeight,
				setWeight,
				makeCalculation,
				makeEditable,
				getCosts,
				setCosts,
				setPackability,
			};
		},
	});
</script>
