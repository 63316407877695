
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import Calculator from "../../../../common/components/Calculate.vue";

	import router from "../router";
	import http from "../../../../common/services/http";
	import store from "../../../../common/services/store";

	export default defineComponent({
		components: {
			Calculator,
		},
		setup() {
			const customers = ref([]);
			const costs = ref([]);
			const products = ref([]);
			const packabilities = ref([]);
			const pallets = ref([]);

			const { user } = toRefs(store.state);

			function viewCalculation(result: any) {
				router.replace(`/calculations/${result.id}`);
			}

			onMounted(() => {
				http.get(`/api/users?role=customer&fetch=all`).then(
					(res: any) => {
						customers.value = res.data;
					}
				);

				http.get(`/api/costs?fetch=all`).then((res: any) => {
					costs.value = res.data;
				});

				http.get(`/api/products?fetch=all`).then((res: any) => {
					products.value = res.data;
				});

				http.get(`/api/pallet-types?fetch=all`).then((res: any) => {
					pallets.value = res.data;
				});
			});

			return {
				user,
				customers,
				costs,
				products,
				pallets,
				viewCalculation,
			};
		},
	});
