
	import { defineComponent, reactive, ref, computed } from "vue";
	import http from "../../common/services/http";

	export default defineComponent({
		props: {
			products: Array,
			costs: Array,
			customers: Array,
			user: Object,
			pallets: Array,
		},

		emits: ["calculated"],

		setup(props, { emit }) {
			const product = ref({});
			const calc = reactive({
				user_id: 0,
				product_id: 0,
				state: "select",
				raw_cost: 0,
				product_packability: 75,
				prod_cost: "0.0",
				pack_weight: 0,
				pack_count: 0,
				primary_packaging: 0,
				labelling: 0,
				box_type: 1,
				box_weight: "0.0",
				pallet_type_id: 0,
				margin: 0,
			});
			const states = ref([
				{ value: "select", label: "Select" },
				{ value: "loose", label: "Loose" },
				{ value: "prepack", label: "Prepack" },
			]);

			const boxWeight = computed(() => {
				return ((calc.pack_weight * calc.pack_count) / 1000).toFixed(2);
			});

			function getCosts(type: any) {
				return props.costs?.filter(
					(cost: any) => cost?.category?.slug == type
				);
			}

			function setCosts() {
				const perc = product.value["packability"] / 100;

				calc.prod_cost = (calc.raw_cost / perc).toFixed(2);
			}

			function setProduct(p: any) {
				calc.product_id = p;

				const pr: any = props.products?.find((pt: any) => pt.id == p);
				product.value = pr;
				calc.raw_cost = pr.cost;
			}

			function setWeight() {
				calc.box_weight = boxWeight.value;
			}

			function setPackability(state: any) {
				calc.product_packability = product.value["packability"];

				setCosts();
			}

			function makeCalculation() {
				http.post("/api/calculations", calc).then((res: any) => {
					emit("calculated", res.data);
				});
			}

			function makeEditable(e: any) {
				e.preventDefault();
				const xs = document.getElementsByTagName("input");

				for (let i = 0; i < xs.length; i++) {
					xs[i].removeAttribute("readonly");
				}
			}

			return {
				states,
				calc,
				product,
				setProduct,
				boxWeight,
				setWeight,
				makeCalculation,
				makeEditable,
				getCosts,
				setCosts,
				setPackability,
			};
		},
	});
